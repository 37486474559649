<template>
  <div
    v-if="!whatsNew.whatsNewIsLoading"
  >
    <div class="update__header-wrapper">
      <mi-list
        virtual-scroll="true"
        virtual-scroll-slice-size="30"
      >
        <mi-list-item
          v-for="( update, key ) in whatsNew.filteredNewData ? whatsNew.filteredNewData : whatsNew.whatsNewData"
          :key="key"
          virtual-scroll="true"
        >
          <div class="update__date">
            <mi-separator class="update__date-separator"></mi-separator>
            <div id="new-date">
              {{
                new Date(update.version_timestamp).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit'
                })
              }}
            </div>
            <mi-separator class="update__date-separator"></mi-separator>
          </div>
          <mi-list-item-section
            v-for="( data, id ) in getData(update)"
            :key="id"
          >
            <div v-if="data.content.topics.length">
              <div class="whats-new-header-wrapper">
                <div v-if="data.section === 'Now you can'">
                  <img src="@/assets/images/checks-whats-new/new-feature.svg" alt="new-feature" />
                </div>
                <div v-else>
                  <img src="@/assets/images/checks-whats-new/bug-fix.svg" alt="big-fix" />
                </div>
                <h4 class="update__header text-uppercase">
                  {{ data.section }}
                </h4>
              </div>
              <div v-if="Array.isArray(data.product_name)" class="update__chip-section">
                <div
                  v-for="(product_name) in data.product_name"
                  :key="product_name"
                >
                  <mi-chip
                    :key="product_name"
                    :label="product_name"
                  ></mi-chip>
                </div>
              </div>
              <div v-else class="update__chip-section">
                <mi-chip
                  :key="key"
                  :label="data.product_name"
                ></mi-chip>
              </div>
              <div
                v-for="(topic, keyTopic) in data.content.topics"
                :key="keyTopic"
              >
                <!-- //NOSONAR --><li class="update__topics">
                  {{ topic }}
                </li>
              </div>
            </div>
          </mi-list-item-section>
          <mi-btn
            v-show="$canAccess('ADMIN')"
            class="update__delete-btn"
            @click="handleDelete( update.application_name, update.version_timestamp)"
          >
            Delete update
          </mi-btn>
        </mi-list-item>
      </mi-list>
    </div>
    <div
      v-if="!whatsNew.filteredNewData?.length"
    >
      <empty-whats-new></empty-whats-new>
    </div>
    <div
      v-if="whatsNew.filteredNewData?.length"
      class="update__end-of-updates-wrapper text-center"
    >
      <span
        class="update__end-of-updates"
      >
        You've reached the end of the updates list
      </span>
    </div>
  </div>
  <div
    v-if="whatsNew.whatsNewIsLoading && !whatsNew.whatsNewData?.length"
  >
    <div
      v-for="index in 3"
      :key="index"
      class="update__skeleton-wrapper"
    >
      <div
        class="update__skeleton"
      >
        <div
          class="update__skeleton-header"
        >
          <mi-skeleton width="47px" height="44px"></mi-skeleton>
          <mi-skeleton width="119px" height="20px"></mi-skeleton>
        </div>
        <div
          v-for="skeletons in 3"
          :key="skeletons"
        >
          <mi-skeleton width="100%" height="20px"></mi-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmptyWhatsNew from '@/views/whatsNew/updateCard/EmptyWhatsNew.vue'
  import MiList from '@/packages/@mi-library/MiList/MiList.vue'
  import MiListItem from '@/packages/@mi-library/MiList/MiListItem.vue'
  import MiListItemSection from '@/packages/@mi-library/MiList/MiListItemSection.vue'
  import MiSeparator from '@/packages/@mi-library/MiSeparator/MiSeparator.vue'
  import MiChip from '@/packages/@mi-library/MiChip/MiChip.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { deleteWhatsNewData } from '@/api'
  import notify from '@/utils/notify'
  import MiSkeleton from '@/packages/@mi-library/MiSkeleton/MiSkeleton.vue'
  import { useWhatsNew } from '@/components/whats-new/composables/useWhatsNew'

  export default {
    name: 'UpdateCard',
    components: { EmptyWhatsNew, MiSkeleton, MiBtn, MiListItemSection, MiListItem, MiList, MiSeparator, MiChip },
    setup() {
      const { whatsNew, fetchWhatsNewData } = useWhatsNew()

      const getData = update => (typeof update.data === 'string' ? JSON.parse(update.data) : update.data)

      const handleDelete = async (application, versionTimestamp) => {
        try {
          await deleteWhatsNewData(application, versionTimestamp)
          notify({
            title: 'Success',
            content: 'Delete Successful.',
            type: 'positive',
            progress: true
          })
          await fetchWhatsNewData()
        }
        catch (error) {
          notify({
            title: 'Error',
            content: 'Delete Failed.',
            type: 'negative',
            progress: true
          })
        }
      }

      return {
        whatsNew,
        getData,
        handleDelete
      }
    }
  }
</script>

<style lang="scss" scoped>
  .update {
    &__header-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__header {
      margin: 8px;
    }

    &__chip-section {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &__date {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &__date-separator {
      width: 45%;
    }

    &__topics {
      color: #5b7085;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin: 8px;
    }
  }

  .mi-list-item {
    display: flex;
    flex-direction: column;
  }

  .q-item__section {
    margin: 8px 0;
  }

  .q-chip {
    background: #eaedf3;
    border-radius: 24px;
    border: 0;
    font-weight: 400;
  }

  .whats-new-header-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .update__delete-btn {
    margin: 8px;
    align-self: flex-start;
  }

  .update__end-of-updates-wrapper {
    line-height: 40px;
  }

  .update__end-of-updates {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 5.6px;
  }

  .update__skeleton-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px;
  }

  .update__skeleton {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .update__skeleton-header {
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin: 30px 0;
  }
</style>
