<template>
  <div class="whats-new__header text-uppercase">
    <h1 class="whats-new__title">What's New</h1>
    <div
      class="whats-new__close-icon"
      role="button"
      tabindex="0"
      @click="emitUpdateModelValue"
    >
      <img src="@/assets/images/close_icon.svg" alt="close_icon" />
    </div>
  </div>
  <div>
    <div class="whats-new-tabs">
      <mi-tabs
        v-show="$canAccess('ADMIN')"
        v-model="activeInfoTab"
        class="whats-new-tab-wrapper"
        dense
      >
        <mi-tab
          :badge="explanationBadge"
          :name="INFO_TAB_NAMES.USER"
          class="enable whats-new-tabs__tab"
          label="User's View"
        >
        </mi-tab>
        <mi-tab
          :badge="explanationBadge"
          :name="INFO_TAB_NAMES.ADMIN"
          class="whats-new-tabs__tab"
          :class="canCreate ? 'enable' : 'hide'"
          label="Add new update"
        >
        </mi-tab>
      </mi-tabs>
    </div>

    <mi-tab-panels v-model="activeInfoTab" class="whats-new-panels" keep-alive>
      <mi-tab-panel :name="INFO_TAB_NAMES.USER">
        <updates :whats-new-data="whatsNew.whatsNewData"></updates>
      </mi-tab-panel>

      <mi-tab-panel :name="INFO_TAB_NAMES.ADMIN">
        <create-whats-new></create-whats-new>
      </mi-tab-panel>
    </mi-tab-panels>
  </div>
</template>

<script>
  import { canAccess } from '@/utils/accessCheck'
  import MiTabPanels from '@/packages/@mi-library/MiTabPanels/MiTabPanels.vue'
  import MiTabPanel from '@/packages/@mi-library/MiTabPanels/MiTabPanel.vue'
  import Updates from '@/views/whatsNew/Updates.vue'
  import CreateWhatsNew from '@/components/whats-new/CreateWhatsNew.vue'
  import { computed, onBeforeMount, ref } from 'vue'
  import { useStore } from 'vuex'
  import { INFO_TAB_NAMES } from '@/constants/whatsNew'
  import { NOTIFICATION_TYPES } from '@/constants/notifications'
  import { useNotificationsState } from '@/composables/useNotificationsState'
  import { useWhatsNew } from '@/components/whats-new/composables/useWhatsNew'

  export default {
    name: 'WhatsNew',
    components: { CreateWhatsNew, MiTabPanel, MiTabPanels, Updates },
    emits: ['open:whats-new'],
    setup(props, { emit }) {
      const canCreate = ref(false)
      const activeInfoTab = ref(INFO_TAB_NAMES.USER)
      const explanationBadge = ref('')
      const { fetchNotificationsState } = useNotificationsState()
      const { whatsNew, fetchWhatsNewData } = useWhatsNew()

      const store = useStore()
      const user = computed(() => store.state.user)

      const emitUpdateModelValue = (value = false) => {
        emit('open:whats-new', value)
      }

      const whatsNewChecker = async () => {
        await fetchNotificationsState({
          type: NOTIFICATION_TYPES.whatsNew,
          seen: true
        })
      }

      onBeforeMount(async () => {
        canCreate.value = (user.value.username === 'admin')
          ? canAccess('CREATE_VALIDATION_SETTINGS')
          : canAccess('CREATE_NOT_USABLE_CHOICES_SETTINGS')
        await fetchWhatsNewData()
        await whatsNewChecker()
      })

      return {
        emitUpdateModelValue,
        canCreate,
        activeInfoTab,
        explanationBadge,
        INFO_TAB_NAMES,
        canAccess,
        whatsNew
      }
    }
  }
</script>

<style lang="scss" scoped>
  .whats-new {
    &__header {
      display: flex;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      padding: 16px 24px;
      background-color: #eaedf3;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      margin: 0;
    }

    &__close-icon {
      cursor: pointer;
    }
  }

  .hide {
    display: none;
  }

  .configuration-info__tab.q-tab--active,
  .configuration-info__tab-panel {
    background-color: $mi-silver-50;
  }

  .whats-new-tabs {
    width: 25vw;
    margin: .5em 1.75em;

    .q-tabs--dense .q-tab {
      min-height: 45px;
    }

    &__tab {
      width: 15%;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $mi-anthracite-800;
      font-family: "MAN Europe Condensed", sans-serif;
    }

    &__tab:nth-child(2) {
      width: 25%;
    }
  }
</style>
